.timeline {
    position: relative;
    margin: 50px auto;
    max-width: 600px;
}

.timeline-item {
    margin: 20px 0;
    padding-left: 60px;
    position: relative;
}

.timeline-item::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 20px;
    width: 20px;
    height: 20px;
    background-color: gray;
    border-radius: 50%;
}

.timeline-item::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 30px;
    width: 2px;
    height: 100%;
    background-color: gray;
}

.timeline-item:last-child::after {
    height: 0;
}

.timeline-item-content {
    padding: 15px;
    background-color: black;
    border-radius: 6px;
    color: #fff;
}

.timeline-item-content h6 {
    margin: 0 0 5px;
    color: #fff;
}

.timeline-item-content p {
    margin: 5px 0;
    font-size: 14px;
    color: #ccc;
}

.timeline-item-content a {
    text-decoration: none;
    color: #00bcd4;
}

/* Responsive Design */
@media (max-width: 600px) {
    .timeline-item {
        padding-left: 50px;
    }

    .timeline-item::before {
        left: 10px;
    }

    .timeline-item::after {
        left: 20px;
    }
}